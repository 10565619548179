// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** branding **/
  --branding-blue: #0a5cb9;
  --branding-teal: #22acef;
  --branding-green: #7cae40;
  --branding-pink: #cb2b75;
  --branding-dark: #243743;

  /* app color */
  --app-color: #d376fa;
  --app-color-hover: #e5f5ff;

  /* app gradient */
  --app-gradient: linear-gradient(
    0deg,
    hsla(269, 85%, 41%, 1) 0%,
    hsla(328, 75%, 45%, 1) 100%
  );
  --app-gradient-compat: hsla(269, 85%, 41%, 1);
  --app-gradient-color-1: hsla(269, 85%, 41%, 1);
  --app-gradient-color-2: hsla(328, 75%, 45%, 1);

  /* padding values */
  --padding-size-minimal: 4px;
  --padding-size-x-small: 8px;
  --padding-size-small: 12px;
  --padding-size-normal: 16px;
  --padding-size-large: 24px;
  --padding-size-x-large: 32px;
  --padding-size-huge: 48px;

  /* font size values */
  --font-size-x-small: 12px;
  --font-size-small: 14px;
  --font-size-normal: 16px;
  --font-size-large: 24px;
  --font-size-x-large: 32px;
  --font-size-huge: 48px;

  /* misc theme values */
  --border-radius-size-normal: 16px;

  /* Box shadow */
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /** Background shading colors: boxes, cards **/
  --background-color-step-1: rgba(255, 255, 255, 1);
  --background-color-step-2: rgba(255, 255, 255, 0.6);
  --background-color-step-3: rgba(255, 255, 255, 0.4);
  --background-color-step-4: rgba(255, 255, 255, 0.1);
  --background-color-step-5: rgba(255, 255, 255, 0.05);

  /** Contast colors: fonts, borders, etc. **/
  --contrast-color-step-1: rgba(0, 0, 0, 1);
  --contrast-color-step-2: rgba(0, 0, 0, 0.6);
  --contrast-color-step-3: rgba(0, 0, 0, 0.4);
  --contrast-color-step-4: rgba(0, 0, 0, 0.1);
  --contrast-color-step-5: rgba(0, 0, 0, 0.05);

  --ion-color-primary: #1d4f9c;
  --ion-color-primary-rgb: 29, 79, 156;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1a4689;
  --ion-color-primary-tint: #3461a6;

  --ion-color-secondary: #54a446;
  --ion-color-secondary-rgb: 84, 164, 70;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #4a903e;
  --ion-color-secondary-tint: #65ad59;

  --ion-color-tertiary: #22acef;
  --ion-color-tertiary-rgb: 96, 48, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #542ae0;
  --ion-color-tertiary-tint: #7045ff;

  /** success **/
  --ion-color-success: #00995e;
  --ion-color-success-rgb: 0, 153, 94;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #008753;
  --ion-color-success-tint: #1aa36e;

  /** warning **/
  --ion-color-warning: #dba700;
  --ion-color-warning-rgb: 219, 167, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c19300;
  --ion-color-warning-tint: #dfb01a;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-item-border-color: var(--ion-background-color-step-200);

  /** User colors **/
  --user-color-self: var(--ion-color-secondary);
  --user-color-self-contrast: #fff;
  --user-color-0: rgb(0, 135, 172);
  --user-color-0-contrast: #fff;
  --user-color-1: rgb(168, 35, 35);
  --user-color-1-contrast: #fff;
  --user-color-2: rgb(200, 99, 31);
  --user-color-2-contrast: #fff;
  --user-color-3: rgb(198, 59, 187);
  --user-color-3-contrast: #fff;
  --user-color-4: rgb(96, 66, 10);
  --user-color-4-contrast: #fff;
  --user-color-5: rgb(156, 200, 67);
  --user-color-5-contrast: #000;
  --user-color-6: rgb(223, 196, 20);
  --user-color-6-contrast: #000;
  --user-color-7: rgb(82, 35, 168);
  --user-color-7-contrast: #fff;
  --user-color-8: rgb(168, 35, 139);
  --user-color-8-contrast: #fff;
  --user-color-9: rgb(102, 34, 79);
  --user-color-9-contrast: #fff;

  --max-width: 1200px;

}

/* This sets a different background and item background for the default theme on ios */
:root.ios {
  --ion-background-color: var(--ion-background-color-step-50, #ffffff);
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/* This sets a different background and item background for the default theme on md */
:root.md {
  --ion-background-color: var(--ion-background-color-step-100, #ffffff);
  --ion-toolbar-background: var(--ion-background-color);
  --ion-item-background: #fff;
}

/*
 * Dark Theme
 * -------------------------------------------
 */

/* This sets a different item background when dark mode is enabled on ios and md */
.ion-palette-dark.ios,
.ion-palette-dark.md {
  --ion-color-primary: #4b89e7;
  --ion-color-primary-rgb: 75, 137, 231;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #4279cb;
  --ion-color-primary-tint: #5d95e9;

  --ion-color-secondary: #7ed270;
  --ion-color-secondary-rgb: 126, 210, 112;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #6fb963;
  --ion-color-secondary-tint: #8bd77e;

  --ion-color-tertiary: #80c3e5;
  --ion-color-tertiary-rgb: 128, 195, 229;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #71acca;
  --ion-color-tertiary-tint: #8dc9e8;

  --ion-color-success: #0bd588;
  --ion-color-success-rgb: 11, 213, 136;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #0abb78;
  --ion-color-success-tint: #23d994;

  --ion-color-warning: #fbd24b;
  --ion-color-warning-rgb: 251, 210, 75;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ddb942;
  --ion-color-warning-tint: #fbd75d;

  --ion-color-danger: #ff9eab;
  --ion-color-danger-rgb: 255, 158, 171;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e08b96;
  --ion-color-danger-tint: #ffa8b3;

  /** Background shading colors: boxes, cards **/
  --background-color-step-1: rgba(0, 0, 0, 1);
  --background-color-step-2: rgba(0, 0, 0, 0.6);
  --background-color-step-3: rgba(0, 0, 0, 0.4);
  --background-color-step-4: rgba(0, 0, 0, 0.1);
  --background-color-step-5: rgba(0, 0, 0, 0.05);

  /** Contast colors: fonts, borders, etc. **/
  --contrast-color-step-1: rgba(255, 255, 255, 1);
  --contrast-color-step-2: rgba(255, 255, 255, 0.6);
  --contrast-color-step-3: rgba(255, 255, 255, 0.4);
  --contrast-color-step-4: rgba(255, 255, 255, 0.1);
  --contrast-color-step-5: rgba(255, 255, 255, 0.05);

  --ion-item-background: #1c1c1d;
}
