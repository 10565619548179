/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
@import "@ionic/angular/css/palettes/dark.class.css";
// @import '@ionic/angular/css/palettes/dark.system.css';

*,
ion-item,
ion-label {
  font-family: "Figtree", sans-serif !important;
  font-smooth: always;
  --webkit-font-smoothing: subpixel-antialiased;
  --moz-osx-font-smoothing: grayscale;
}

ion-button.button-outline {
  --border-width: 1px;
}

ion-segment {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--contrast-color-step-4);

  ion-segment-button {
    min-width: 90px;
    letter-spacing: normal;
    font-size: 16px;
    width: 20vw;
    max-width: 150px;
    text-transform: none;
  }
}

.terms {
  border-radius: 8px;
  border: 1px solid var(--contrast-color-step-3);
  padding: 16px;
  margin-bottom: 24px;
  max-height: 30vh;
  overflow-y: auto;

  h2 {
    font-size: 20px;
  }
}

.tb-buttons-container {
  flex: 1 1 auto;
  display: flex;
}

.field-item {
  ion-label {
    max-width: 150px;
    font-weight: bold;
    font-size: 14px;
  }
}

img.thumbnail {
  height: 28px;
  width: 28px;
}

ion-list-header a {
  color: var(--contrast-color-step-1);
  text-decoration: none;
}

// ion-icon {
//   --ionicon-stroke-width: 20px;
// }

@media (max-width: 767px) {
  #medchat-launcher-frame {
    bottom: 70px !important;
  }

  ion-segment-button {
    flex: 1 1 auto;
  }

  .tb-buttons-container {
    display: flex;
    width: 100%;
    margin-top: 16px;

    ion-button {
      flex: 1 1 auto;
    }
  }

  bh-header {
    ion-button {
      color: #fff !important;
      --padding-start: 8px;
      --padding-end: 8px;
      --padding-top: 0;
      --padding-bottom: 0;
    }
  }

  bh-toolbar {
    ion-button {
      flex: 1 1 auto;
    }
  }
}
